html {
  font-size: 62.5%;
  font-family: var(--helvetica);
}
@font-face {
  font-family: "Helvetica";
  src: local("Helvetica"), url(../assets/fonts/Helvetica.ttf) format("truetype");

  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: "HelveticaLight";
  src: local("Helvetica-Light"),
    url(../assets/fonts/Helvetica-Light.otf) format("otf");
}

// @font-face {
//   font-family: 'Helvetica';
//   src: local('Helvetica'), url(../assets/fonts/Helvetica-Bold.ttf) format('truetype');
//   /* other formats include: 'woff2', 'truetype, 'opentype',
//                             'embedded-opentype', and 'svg' */
// }
body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Helvetica";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
button,
a,
div {
  font-family: var(--helvetica);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.m-bottom85 {
  margin-bottom: 8.5rem;
}
.m-bottom70 {
  margin-bottom: 7rem;
}

.app {
  text-align: center;
  width: 100%;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.app-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-link {
  color: #61dafb;
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// overwrite bootstrap css
.form-control:focus {
  box-shadow: none !important;
  border-color: black !important;
}

:root {
  --heading: 4.2rem;
  --sub-head: 2.4rem;
  --sub-head2: 2.2rem;
  --body1: 1.8rem;
  --body2: 1.6rem;
  --body3: 1.4rem;
  --helvetica: Helvetica;
  --thin: 400;

  --black: #333333;
  --white: #ffffff;
  --gray: #eeeeee;
  --dark: #000000;
  --light-dark: #6c6c6c;
}
